import { Col, Row } from 'reactstrap';
import {
  Container,
  Paragraph,
} from '../components/styles-components/BlogStyles';
import { Link, graphql } from 'gatsby';

import Blog from '../components/Blog';
import { I18nProvider } from '@lingui/react';
import Languages from '../components/Languages';
import Layout from '../components/Layout';
import React from 'react';
import { Trans } from '@lingui/macro';
import catalogES from '../locales/es/messages';
import logo1x from '../assets/images/logo@1x.png';
import logo1xwebp from '../assets/images/logo@1xwebp.webp';
import logo2x from '../assets/images/logo@2x.png';
import logo2xwebp from '../assets/images/logo@2xwebp.webp';
import logo3x from '../assets/images/logo@3x.png';
import logo3xwebp from '../assets/images/logo@3xwebp.webp';
import logo4x from '../assets/images/logo@4x.png';
import logo4xwebp from '../assets/images/logo@4xwebp.webp';
import logo5x from '../assets/images/logo@5x.png';
import logo5xwebp from '../assets/images/logo@5xwebp.webp';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useState } from 'react';

const BlogPost = props => {
  const { data, pageContext } = props;
  const post = data.markdownRemark;
  const { previous, next } = pageContext;
  const image =
    post.frontmatter && post.frontmatter.thumbnail.childImageSharp.fluid;

  const { allMarkdownRemark } = data;

  const [translate] = useState(false);

  useEffect(() => {
    typeof window !== `undefined` &&
      window.localStorage.setItem('translate', translate);
  });

  return (
    <I18nProvider language="es" catalogs={{ es: catalogES }}>
      <Layout>
        <Languages lang="es" translate={translate} />
        <section id="banner" style={{ position: 'relative', zIndex: '10' }}>
          <div className="inner">
            <div className="logo">
              <picture>
                <source
                  srcSet={`${logo1xwebp} 833w, ${logo2xwebp} 1667w, ${logo3xwebp} 2500w, ${logo4xwebp} 3334w, ${logo5xwebp} 4167w`}
                  className="logo"
                  type="image/webp"
                />
                <img
                  srcSet={`${logo1x} 833w, ${logo2x} 1667w, ${logo3x} 2500w, ${logo4x} 3334w, ${logo5x} 4167w`}
                  alt=""
                  style={{
                    marginBottom: '7vh',
                  }}
                />
              </picture>
            </div>
            {post.frontmatter !== undefined && (
              <div>
                <p
                  style={{
                    fontSize: '1.1em',
                    fontWeight: 500,
                  }}
                >
                  {post.frontmatter.date}
                </p>
                <h2
                  style={{
                    fontSize: '1.1em',
                    fontWeight: 500,
                  }}
                >
                  {post.frontmatter.title}{' '}
                </h2>
                <p>
                  <Link to="/es">
                    <Trans>Home</Trans>
                  </Link>
                </p>
              </div>
            )}
          </div>
        </section>
        <section className="wrapper style1 special">
          <Container className="cd-section">
            <Row className="my-5">
              <Col
                sm="12"
                md="6"
                style={{
                  height: '60px',
                }}
              >
                {previous && previous.frontmatter !== undefined && (
                  <PageLink to={`/${previous.slug}`}>
                    &#171; <small>{previous.frontmatter.title}</small>
                  </PageLink>
                )}
              </Col>
              <Col
                sm="12"
                md="6"
                style={{
                  height: '60px',
                }}
              >
                {next && next.frontmatter !== undefined && (
                  <PageLink to={`/${next.slug}`}>
                    <small>{next.frontmatter.title}</small> &#187;
                  </PageLink>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <img
                  className="img-fluid"
                  srcSet={image.srcSet}
                  style={{ marginBottom: '4em' }}
                  alt=""
                />
                <Paragraph
                  dangerouslySetInnerHTML={{
                    __html: post.html,
                  }}
                ></Paragraph>
              </Col>
            </Row>
          </Container>
          <section>
            <Blog title={'Más artículos'} data={allMarkdownRemark} />
          </section>
        </section>
      </Layout>
    </I18nProvider>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlugEs($id: String) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        language
        thumbnail {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 100
      filter: { frontmatter: { language: { eq: "es" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            language
            thumbnail {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 150)
        }
      }
    }
  }
`;

const PageLink = styled(Link)`
  margin-left: 0.5em;
  margin-right: 0.5em;
  border: 1px solid #fff;
  box-shadow: none !important;
  transition: all 0.2s ease-in-out;
  border-radius: 0;
  padding: 1em 2em;
  color: white;
  &:hover {
    background-color: white;
    color: #38aeee;
  }
`;
