import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 10;
  color: white;
  padding: 0px 20px;
  &::after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.125);
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
  }
`;

export const Paragraph = styled.div`
  font-size: 15px;
  font-weight: 40;
  text-align: left;
  margin-bottom: 2em;
  p {
    margin-bottom: 2.5em;
  }
  ul > li > h1 {
    font-size: 1.6rem;
  }

  table > thead > tr > th {
    text-align: center;
  }

  table > tbody > tr > td > strong {
    font-weight: bold;
  }
`;
